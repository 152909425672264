import React from "react"
import { graphql } from "gatsby"
import PageWrapper from "../components/Layout/PageWrapper"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ImageCarousel from "../components/Weekmenu/ImageCarousel";
import { Container, Col } from "react-bootstrap";
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SEO from "../components/seo";

const WeekmenuTemplate = ({ data }) => {
    const { title, images, content } = data.weekmenu;

    const Bold = ({ children }) => <b className="font-weight-bold">{children}</b>;

    const Text = ({ children }) => <p className="align-center">{children}</p>;

    const List = ({ children }) => <li className="pb-3 pt-3">{children}</li>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
                return <Text>{children}</Text>
            },
            [BLOCKS.UL_LIST]: (node, children) => {
                return <List>{children}</List>
            },
            [BLOCKS.HEADING_6]: (node, children) => {
                return <h6 className="pt-3">{children}</h6>
            },
            [INLINES.EMBEDDED_ENTRY]: (node, children) => {
                return <Text>{children}</Text>
            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const slug = node.data.target.fields.slug["en-US"]
                return <a className="a-home" href={`localhost:8000/recipes/${slug}`} target="_blank" rel="noopener noreferrer">{children}</a>
            },
            [INLINES.HYPERLINK]: (node, children) => {
                return <a className="a-home" href={node.data.uri} target="_blank" rel="noopener noreferrer">{node.content[0].value}</a>
            }
        }
    };
    return (
        <PageWrapper>
            <SEO title={title} />
            <Container className="d-flex justify-content-center under-nav" fluid>
                <Col lg={8}>
                    <h1 className="text-center">{title}</h1>
                    <ImageCarousel images={images} />

                    {/* <div dangerouslySetInnerHTML={{ __html: content.childContentfulRichText.html }} /> */}
                    <Container fluid className="pt-5">
                    {documentToReactComponents(content.json, options)}
                    </Container>
                    <Container className="pt-5 pb-5 d-flex justify-content-center">
                        <AniLink fade to="/weekmenu/" className="btn-blue">Terug naar alle weekmenu's</AniLink>
                    </Container>
                </Col>
            </Container>

        </PageWrapper>
    )
}

export default WeekmenuTemplate;

export const query = graphql`
query ($slug: String!) {
  weekmenu: contentfulWeekmenu(slug: { eq: $slug }) {
    title
    date(formatString: "DD MMMM YYYY", locale: "nl")
    images {
      id
      fluid(quality: 100, maxHeight: 750) {
        ...GatsbyContentfulFluid_withWebp
        srcWebp
      }
      title
    }
    content {
        childContentfulRichText {
          html
        }
        json
      }
  }
}
`;


