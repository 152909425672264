import React from 'react';
import Img from "gatsby-image";
import { Carousel, Col, Container } from "react-bootstrap";

const ImageCarousel = ({images}) => {


    return (
        <Container className="d-flex justify-content-center m-0 p-0" fluid>
        <Col lg={10}>
            <Carousel>
                {images.map(({id, fluid, title}) => (
                        <Carousel.Item key={id}>
                            <Img fluid={fluid} alt={title}/>
                        </Carousel.Item>
                ))}
            </Carousel>
        </Col>
    </Container>
    )
}

export default ImageCarousel;
